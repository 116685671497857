.sell-old{
      /* margin: 0 !important; */
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
      font-weight: 700 !important;
      font-size: 1.300rem !important;
      line-height: 1.235 !important;
      letter-spacing: 0.00735em !important;
      text-align: center !important;
}
.brandnamemodel {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      font-weight: bold !important;
      font-size: 0.625rem !important;
      /* text-align: center !important; */
      white-space: nowrap !important;
      
  }


@media (max-width: 600px) {
  .brandnamemodel {
    font-size: 0.425rem !important; 
    /* text-align: center !important;     */
    font-weight: bold !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    white-space: nowrap !important;
  }
}

  .selectmodelseriesfor {
      margin: 0 !important;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      font-weight: 400 !important;
      font-size: 1rem !important;
      line-height: 1.3 !important;
      letter-spacing: 0.00938em !important;
      text-align: center !important;
  }

  .conditionsdesc{
    margin: 0 !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: bold !important;
    font-size: 0.685rem !important;
    line-height: 1.40 !important;
    /* letter-spacing: 0.01071em; */
    padding: 8px !important;
    /* white-space: nowrap; */
    color:black !important;
  }
  .conditionsheading{
    margin: 0 !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.134 !important;
    letter-spacing: 0em !important;
    margin-bottom: 3% !important;
    color:black !important;

  }

  .conditionssubheading{
    /* margin: 0 !important; */
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: bold !important;
    font-size: 0.9rem !important; 
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-bottom: 3% !important;
    color:#707070 !important;
    margin-top:10% !important;
    text-decoration: underline !important;
    /* text-align: center !important; */


  }

  .confirm-order{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    margin-bottom: 0.35em;
    color:#4BCEBD !important;
  }

  .confirmatonback{
   /* background-color: #06A89D !important; */
    color: #fff !important;
    margin-top: 2px;
   
    border-radius:25px !important;
  }

  .cancel-order{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    margin-bottom: 0.35em;
    color:red;
  }
  .our-services{
    font-size: 0.625rem !important;
    font-weight: bold !important;
    line-height: 1.4 !important;
    /* letter-spacing: 0.0075em !important; */
    margin-bottom: 0.35em !important;
    /* margin: 0 !important; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    white-space:nowrap !important;
  }

  .lets-get-started{
    font-size: 1.3rem !important;
    font-weight: 900 !important;
    text-align: center;
  }
  .menuItems{
    font-size: 0.5rem !important;
    font-weight: 700 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;

  }
  .usernamedropdown{
    position: absolute !important;
    top: 2% !important;
    right: 11% !important;
    color: white !important;
  }

  .coswdesc{
    /* margin: 0; */
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em !important;
    text-align: center !important;
    margin-top:2% !important;

  }

  .cosacc{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400 !important;
    font-size: 0.925rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
  .footermenus{
    margin: 0 !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.825rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em !important;
    display: block !important;
  }


  .footermenus {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footermenu-item {
    margin: 0.5rem 0; /* Adjust spacing as needed */
  }
  
  .footermenu-item .MuiListItemText-primary {
    font-size: 0.825rem;
    cursor: pointer;
    color: #333; /* Adjust color as needed */
  }
  
  .footermenu-item:hover .MuiListItemText-primary {
    text-decoration: underline;
    color: #000; /* Change the color on hover */
  }


  .footer-heading {
    font-weight: bold; /* Make the heading bold */
    color: #444; /* A subtle dark color */
    margin-bottom: 0.5rem; /* Space between heading and list */
    padding-bottom: 0.25rem; /* Adjust spacing below the text */
    font-size: 1.25rem; /* Slightly increase font size */
    text-align: left !important;
  }

  .Appbar{
      background-color: #fff !important;
      box-shadow: none !important;
      border-bottom: 1px solid #e0e0e0 !important;
      color:#000 !important;
  }

  .LoginBtn{
    --tw-bg-opacity: 1 !important;
    background-color: #4BCEBD !important;
    color:#fff !important;
    font-weight: bold !important;
    border-radius: 10px !important;
  }
  .globalbgColor{
    background-color: #4BCEBD !important;
  }
  .globalColor{
    background-color: #4BCEBD !important;
    color:#fff !important;
    font-weight: bold !important;
  }
  .go-back-button {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 6px 12px;
    border-radius: 20px !important;
    border: 2px solid #4BCEBD;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    color: #4BCEBD;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-transform: capitalize !important;
  }
  
  .go-back-button:hover {
    background-color: rgba(75, 206, 189, 0.1);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  }
  
  .go-back-icon {
    color: #4BCEBD;
    margin-right: 8px;
  }
  
  .go-back-text {
    font-weight: bold;
    color: #4BCEBD;
  }
  

  .BrandCardStyles{
    display: flex !important;
    object-fit: contain !important;
    /* display: block; */
    margin: auto !important;
    max-width: 70px !important;
    padding: 4% !important;
    text-align: center !important;
    justify-content: center !important;
  }

  

  @media (max-width: 600px) {
    .varinat {
      font-size: 10px !important; 
     
    }
  }



  .sticky-container {
    position: fixed;
    right: 20px;
    top: 51%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
  }
  
  .icon {
    background-color: #4BCEBD; /* Green background */
    color: white;
    padding: 9px;
    border-radius: 20%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .icon:hover {
    transform: scale(1.1);
    background-color: #4BCEBD; /* Darker green on hover */
  }
  
  .sliderContainerRoot {
    max-width: 1800px !important;
  }